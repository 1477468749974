import { OrientationType } from "@jackfruit/common"
import { EntityId } from "@reduxjs/toolkit"
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { lineItems, lineItemsSelectors } from "~/redux/state/lineItems"
import { RootState } from "~/redux/store"
import { usePageCart } from "./usePageCart"
import { useProcessActions } from "./useProcessActions"
import { useProduct } from "./useProduct"

export const useLineItem = (id: EntityId) => {
  const lineItem = useSelector((state: RootState) =>
    lineItemsSelectors.selectById(state, id)
  )!

  const { product } = useProduct(lineItem.productId)
  let productType = product?.metaData?.frame ? "frame" : "print"
  if (
    lineItem.productTemplateId !== undefined &&
    lineItem.productTemplateVariantId !== undefined
  ) {
    productType = "template"
  }

  return {
    lineItem,
    product: {
      ...product,
      productType,
    },
  }
}

export const useLineItemActions = () => {
  const process = useProcessActions()
  const dispatch = useDispatch()
  const {
    pageCart: { fulfillment, storeId, couponCode },
  } = usePageCart()
  //Send coupon code only if delivery or store is selected
  const isApplyCoupon =
    (fulfillment === "delivery" || storeId !== 0) && couponCode !== ""

  return {
    setEditing: useCallback(
      (id: EntityId, isEditing: boolean) => {
        dispatch(
          lineItems.actions.updateOne({
            id,
            changes: {
              isEditing,
            },
          })
        )
      },
      [dispatch]
    ),
    updateProduct: useCallback(
      (id: EntityId, productId: EntityId) => {
        process.updateLineItemProduct({
          lineItemId: id,
          productId,
          event: "productChanged",
        })
        isApplyCoupon && process.applyCouponCode()
      },
      [isApplyCoupon, process]
    ),
    setGiftCertificate: useCallback(
      (id: EntityId, giftCertificateId: EntityId | null) => {
        dispatch(
          lineItems.actions.updateOne({
            id,
            changes: {
              giftCertificateId,
            },
          })
        )
      },
      [dispatch]
    ),
    updateQuantity: useCallback(
      (id: EntityId, quantity: number) => {
        dispatch(
          lineItems.actions.updateOne({
            id,
            changes: {
              quantity,
            },
          })
        )
        process.updateOrderSummary({
          reason: "lineItem quantity changed",
          reasonType: "cartChange",
        })
        isApplyCoupon && process.applyCouponCode()
      },
      [dispatch, isApplyCoupon, process]
    ),
    updateOrientation: useCallback(
      (id: EntityId, orientation: OrientationType) => {
        process.updateLineItemOrientation({ lineItemId: id, orientation })
      },
      [process]
    ),
    updateMissingImages: useCallback(
      (
        id: EntityId,
        files: File[],
        onOpen?: () => void,
        onClose?: () => void
      ) => {
        process.updateMissingImagesForLineItem({
          lineItemId: id,
          files,
          onOpen,
          onClose,
        })
      },
      [process]
    ),
    setUpdateMissingImagesDialogChoice: useCallback(
      (choice: boolean) => {
        process.setUpdateMissingImagesDialogChoice({ choice })
      },
      [process]
    ),
    remove: useCallback(
      (id: EntityId) => {
        process.removeLineItem({ lineItemId: id })
        isApplyCoupon && process.applyCouponCode()
      },
      [isApplyCoupon, process]
    ),
    removeMany: useCallback(
      (ids: EntityId[]) => {
        dispatch(lineItems.actions.removeMany(ids))
        isApplyCoupon && process.applyCouponCode()
      },
      [dispatch, isApplyCoupon, process]
    ),
    updateProductPageIds: useCallback(
      (id: EntityId, productPageIds: EntityId[]) => {
        dispatch(
          lineItems.actions.updateOne({
            id,
            changes: {
              productPageIds,
            },
          })
        )
      },
      [dispatch]
    ),
    insertPageSpreadAfter: useCallback(
      (
        id: EntityId,
        productPageId: EntityId,
        callback?: (id: EntityId) => void
      ) => {
        process.insertPageSpreadToLineItemAfter({
          lineItemId: id,
          productPageId,
          callback,
        })
      },
      [process]
    ),
    insertPageSpreadBefore: useCallback(
      (
        id: EntityId,
        productPageId: EntityId,
        callback?: (id: EntityId) => void
      ) => {
        process.insertPageSpreadToLineItemBefore({
          lineItemId: id,
          productPageId,
          callback,
        })
      },
      [process]
    ),
    removePageSpread: useCallback(
      (
        id: EntityId,
        productPageId: EntityId,
        callback?: (id: EntityId) => void
      ) => {
        process.removePageSpreadFromLineItem({
          lineItemId: id,
          productPageId,
          callback,
        })
      },
      [process]
    ),
    removeIncompletePageSpreads: useCallback(
      (id: EntityId) => {
        process.removeIncompletePageSpreadsFromLineItem({
          lineItemId: id,
        })
      },
      [process]
    ),
  }
}
