import { Box, useDisclosure } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React, { useCallback } from "react"
import { isIOS } from "react-device-detect"
import { useRecoilCallback } from "recoil"
import { useHasImagesForAllImageRegions } from "~/hooks/useHasImagesForAllImageRegions"
import { useLineItem, useLineItemActions } from "~/hooks/useLineItem"
import { useNextActionableBlock } from "~/hooks/useNextActionableBlock"
import { useProcessActions } from "~/hooks/useProcessActions"
import { scrollTo } from "~/services/Utils"
import EditorActionButtons from "../common/EditorActionButtons"
import { EditorNoPhotoConfirmation } from "../print/PrintEditorSidebar"
import { EditorMissingPhotoOnPageCancelConfirmation } from "./PhotoBookEditorSidebar"

interface Props {
  lineItemId: EntityId
  onContinue: () => void
  onCancel: () => void
}

const PhotoBookEditorBottombarMobile: React.FC<Props> = ({
  lineItemId,
  onCancel,
  onContinue,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenCancelModal,
    onOpen: openCancelModal,
    onClose: closeCancelModal,
  } = useDisclosure()
  const { lineItem } = useLineItem(lineItemId)
  const isTemplate = lineItem.isTemplate

  const { nextBlockType } = useNextActionableBlock("template")
  const { remove, removeIncompletePageSpreads } = useLineItemActions()
  const { applyEditorChanges } = useProcessActions()
  const hasImagesForAllImageRegions = useHasImagesForAllImageRegions(lineItemId)
  const hasMultiplePages = lineItem.productPageIds.length > 1

  const handleSaveChanges = useRecoilCallback(({ snapshot }) => async () => {
    applyEditorChanges({ lineItemId, snapshot })
    onContinue()
    isTemplate && scrollTo(nextBlockType)
    onClose()
  })

  const onContinueEnhanced = useCallback(() => {
    if (!hasImagesForAllImageRegions) {
      onOpen()
      return
    }

    handleSaveChanges()
  }, [handleSaveChanges, hasImagesForAllImageRegions, onOpen])

  const handleCancel = useCallback(() => {
    // this editor is opened as a preview and we have
    // to remove the line item from the cart if the user
    // hit cancel at the first opening
    if (!lineItem.isConfirmed) {
      remove(lineItem.id)
    } else if (!hasImagesForAllImageRegions) {
      // some pages are missing images, we need to ask confirmation to the user
      // before he left the editor as we will delete faulty spreads
      openCancelModal()
    } else {
      onCancel()
    }
  }, [
    hasImagesForAllImageRegions,
    lineItem.id,
    lineItem.isConfirmed,
    onCancel,
    openCancelModal,
    remove,
  ])

  const handleRemoveEmptyPagesAndClose = useCallback(() => {
    removeIncompletePageSpreads(lineItemId)
    onCancel()
  }, [lineItemId, onCancel, removeIncompletePageSpreads])

  return (
    <>
      <Box mt={4} mb={isIOS ? 10 : 0}>
        <EditorActionButtons
          size="lg"
          viewType="mobile"
          onContinue={onContinueEnhanced}
          onCancel={handleCancel}
        />
      </Box>

      <EditorNoPhotoConfirmation
        onClose={onClose}
        onConfirm={onClose}
        isOpen={isOpen}
        hasMultiplePages={hasMultiplePages}
        isCentered={true}
      />

      <EditorMissingPhotoOnPageCancelConfirmation
        onClose={closeCancelModal}
        onConfirm={handleRemoveEmptyPagesAndClose}
        isOpen={isOpenCancelModal}
      />
    </>
  )
}

export default PhotoBookEditorBottombarMobile
