import {
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { useImageTransform } from "~/hooks/useImageTransform"
import { useProduct } from "~/hooks/useProduct"
import {
  imageTransformationMinZoomStateFamily,
  imageTransformationZoomStateFamily,
  editorCurrentProductId,
} from "./atoms"

interface Props {
  imageTransformId: EntityId
  disabled: boolean
}

const EditorImageZoomSliderTopbar: React.FC<Props> = ({
  imageTransformId,
  disabled,
}) => {
  const { maxZoom: maxZoomDefault, zoomStep: zoomStepDefault } =
    useImageTransform(imageTransformId)
  const [zoom, setZoom] = useRecoilState(
    imageTransformationZoomStateFamily(imageTransformId)
  )

  const minZoomDefault = useRecoilValue(
    imageTransformationMinZoomStateFamily(imageTransformId)
  )

  const productId = useRecoilValue(editorCurrentProductId)
  const { product } = useProduct(productId)
  const isClampingEnabled = Boolean(
    product?.metaData?.web?.editor?.clamp ?? true
  )

  const zoomStep = isClampingEnabled ? zoomStepDefault : zoomStepDefault * 0.5
  const maxZoom = isClampingEnabled ? maxZoomDefault : maxZoomDefault * 0.5
  const minZoom = isClampingEnabled ? minZoomDefault : minZoomDefault * 0.15

  return (
    <Flex w="full" verticalAlign="center">
      <Slider
        width={40}
        isDisabled={disabled}
        defaultValue={1}
        value={zoom}
        min={minZoom}
        max={maxZoom}
        step={zoomStep}
        onChange={setZoom}
        size="lg"
      >
        <SliderTrack boxSize={2} borderRadius={4}>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb borderWidth={3} borderColor="primary.800" boxSize={6} />
      </Slider>
    </Flex>
  )
}

export default EditorImageZoomSliderTopbar
