import { Box, useDisclosure } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React, { useCallback } from "react"
import { isIOS } from "react-device-detect"
import { useRecoilCallback } from "recoil"
import { useHasImagesForAllImageRegions } from "~/hooks/useHasImagesForAllImageRegions"
import { useLineItem, useLineItemActions } from "~/hooks/useLineItem"
import { useNextActionableBlock } from "~/hooks/useNextActionableBlock"
import { useProcessActions } from "~/hooks/useProcessActions"
import { scrollTo } from "~/services/Utils"
import EditorActionButtons from "../common/EditorActionButtons"
import {
  EditorCancelIncompleteConfirmation,
  EditorNoPhotoConfirmation,
} from "./PrintEditorSidebar"

interface Props {
  lineItemId: EntityId
  onContinue: () => void
  onCancel: () => void
}

const PrintEditorBottombarMobile: React.FC<Props> = ({
  lineItemId,
  onCancel,
  onContinue,
}) => {
  const {
    isOpen: isOpenMissingPictureModal,
    onOpen: onOpenMissingPictureModal,
    onClose: onCloseMissingPictureModal,
  } = useDisclosure()
  const {
    isOpen: isOpenCancelConfirmationModal,
    onOpen: onOpenCancelConfirmationModal,
    onClose: onCloseCancelConfirmationModal,
  } = useDisclosure()
  const { lineItem, product } = useLineItem(lineItemId)
  const isTemplate = lineItem.isTemplate

  const { nextBlockType } = useNextActionableBlock("template")
  const { remove } = useLineItemActions()
  const { applyEditorChanges } = useProcessActions()
  const hasImagesForAllImageRegions = useHasImagesForAllImageRegions(lineItemId)
  const hasMultiplePages = lineItem.productPageIds.length > 1

  const handleSaveChanges = useRecoilCallback(({ snapshot }) => async () => {
    applyEditorChanges({ lineItemId, snapshot })
    onContinue()
    isTemplate && scrollTo(nextBlockType)
    onCloseMissingPictureModal()
  })

  const onContinueEnhanced = useCallback(() => {
    if (!hasImagesForAllImageRegions) {
      onOpenMissingPictureModal()
      return
    }

    handleSaveChanges()
  }, [
    handleSaveChanges,
    hasImagesForAllImageRegions,
    onOpenMissingPictureModal,
  ])

  const handleCancel = useCallback(() => {
    // this editor is opened as a preview and we have
    // to remove the line item from the cart if the user
    // hit cancel at the first opening
    if (!lineItem.isConfirmed) {
      remove(lineItem.id)
    }
    onCloseCancelConfirmationModal()
    onCancel()
  }, [
    lineItem.id,
    lineItem.isConfirmed,
    onCancel,
    onCloseCancelConfirmationModal,
    remove,
  ])

  const onCancelEnhanced = useCallback(() => {
    if (product.minImages > 1) {
      onOpenCancelConfirmationModal()
    } else {
      handleCancel()
    }
  }, [handleCancel, onOpenCancelConfirmationModal, product.minImages])

  return (
    <>
      <Box mt={4} mb={isIOS ? 10 : 0}>
        <EditorActionButtons
          size="lg"
          viewType="mobile"
          onContinue={onContinueEnhanced}
          onCancel={onCancelEnhanced}
        />
      </Box>

      <EditorNoPhotoConfirmation
        onClose={onCloseMissingPictureModal}
        onConfirm={onCloseMissingPictureModal}
        isOpen={isOpenMissingPictureModal}
        hasMultiplePages={hasMultiplePages}
      />

      <EditorCancelIncompleteConfirmation
        onClose={onCloseCancelConfirmationModal}
        onConfirm={handleCancel}
        isOpen={isOpenCancelConfirmationModal}
      />
    </>
  )
}

export default PrintEditorBottombarMobile
