import { EntityId } from "@jackfruit/common"
import React, { useCallback } from "react"
import DesktopTopbar from "../common/DesktopTopbar"
import EditorSvgCropperMobileToolBar from "../common/EditorSvgCropperMobileToolBar"
import EditorSvgCropperMobileToolBarLandscape from "../common/EditorSvgCropperMobileToolBarLandscape"
import EditorContainer from "../common/layouts/EditorContainer"
import EditorContainerMobile from "../common/layouts/EditorContainerMobile"
import EditorContainerMobileLandscape from "../common/layouts/EditorContainerMobileLandscape"
import MultiplePagesEditor from "../common/MultiplePagesEditor"
import PhotoBookEditorMobilePageNavigation from "../common/pagination/PhotoBookEditorMobilePageNavigation"
import PhotoBookEditorPageNavigation from "../common/pagination/PhotoBookEditorPageNavigation"
import { ViewType } from "../Editor"
import PrintEditorTopbarMobile from "../print/PrintEditorTopbarMobile"
import PhotoBookEditorBottombarMobile from "./PhotoBookEditorBottombarMobile"
import PhotoBookEditorSideBar from "./PhotoBookEditorSidebar"

interface Props {
  lineItemId: EntityId
  onComplete: () => void
  viewType: ViewType
}

const PhotoBookEditor: React.FC<Props> = ({
  lineItemId,
  onComplete,
  viewType,
}) => {
  const onContinue = useCallback(() => {
    onComplete()
  }, [onComplete])

  const onCancel = useCallback(() => {
    onComplete()
  }, [onComplete])

  if (viewType === "mobile") {
    return (
      <EditorContainerMobile
        topbar={<PrintEditorTopbarMobile lineItemId={lineItemId} />}
        editor={
          <MultiplePagesEditor lineItemId={lineItemId} viewType={viewType} />
        }
        toolbar={<EditorSvgCropperMobileToolBar lineItemId={lineItemId} />}
        bottombar={
          <PhotoBookEditorBottombarMobile
            lineItemId={lineItemId}
            onContinue={onContinue}
            onCancel={onCancel}
          />
        }
        pageNavigator={
          <PhotoBookEditorMobilePageNavigation lineItemId={lineItemId} />
        }
      />
    )
  }

  if (viewType === "mobile landscape") {
    return (
      <EditorContainerMobileLandscape
        topbar={<PrintEditorTopbarMobile lineItemId={lineItemId} />}
        editor={
          <MultiplePagesEditor lineItemId={lineItemId} viewType={viewType} />
        }
        toolbar={
          <EditorSvgCropperMobileToolBarLandscape lineItemId={lineItemId} />
        }
        bottombar={
          <PhotoBookEditorBottombarMobile
            lineItemId={lineItemId}
            onContinue={onContinue}
            onCancel={onCancel}
          />
        }
        pageNavigator={
          <PhotoBookEditorMobilePageNavigation lineItemId={lineItemId} />
        }
      />
    )
  }

  //Desktop view
  return (
    <EditorContainer
      topbar={<DesktopTopbar lineItemId={lineItemId} />}
      sidebar={
        <PhotoBookEditorSideBar
          lineItemId={lineItemId}
          onContinue={onContinue}
          onCancel={onCancel}
          showTools
        />
      }
      editor={
        <MultiplePagesEditor lineItemId={lineItemId} viewType={viewType} />
      }
      pageNavigator={<PhotoBookEditorPageNavigation lineItemId={lineItemId} />}
    />
  )
}

export default PhotoBookEditor
